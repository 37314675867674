import { clsx } from 'clsx'
import Image from 'next/image'
import { useLocale, useTranslations } from 'next-intl'
import { FaAngleDown } from 'react-icons/fa6'
import flagEN from '@/assets/images/navbar/flag-en.png'
import flagVN from '@/assets/images/navbar/flag-vn.png'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { FlagVNSvg, FlagUsSvg, CheckSvg } from '@/icons-svg/HomeSvgIcons'
import { Link, usePathname } from '@/navigation'

function LocaleDropdown() {
  const t = useTranslations('LocaleSwitcher')
  const pathname = usePathname()
  const locale = useLocale()
  const otherLocale = locale === 'en' ? 'vn' : 'en'

  return (
    <div className="nav-item h-full flex items-center">
      <Popover>
        <PopoverTrigger>
          <PopoverToggle2 />
        </PopoverTrigger>
        <PopoverContent>
          <div className="transition-all translate-y-3 bg-white rounded-2.5xl shadow-lg p-4 w-72">
            <div className="flex flex-col">
              <div
                className={clsx('transition-all py-1', {
                  'flex justify-between items-center bg-gray-50 rounded-xl':
                    locale === 'vn',
                })}
              >
                <Link
                  className="flex items-center !p-2"
                  href={pathname}
                  locale={otherLocale}
                >
                  <FlagVNSvg />
                  <span className="pl-4 font-semibold">
                    {t('viet_name', { locale: otherLocale })}
                  </span>
                </Link>
                {locale === 'vn' && (
                  <div className="pr-2">
                    <CheckSvg />
                  </div>
                )}
              </div>
              <div
                className={clsx('transition-all py-1', {
                  'flex justify-between items-center bg-gray-50 rounded-xl':
                    locale === 'en',
                })}
              >
                <Link
                  className="flex items-center !p-2"
                  href={pathname}
                  locale={otherLocale}
                >
                  <FlagUsSvg />
                  <span className="pl-4 font-semibold text-gray-500">
                    {t('english', { locale: otherLocale })}
                  </span>
                </Link>
                {locale === 'en' && (
                  <div className="pr-2">
                    <CheckSvg />
                  </div>
                )}
              </div>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}

function PopoverToggle2() {
  const locale = useLocale()

  return (
    <div className="flex flex-row items-center justify-center gap-[6px] cursor-pointer">
      <Image
        alt="flag"
        height={20}
        src={locale === 'vn' ? flagVN : flagEN}
        width={20}
      />
      <div className="relative leading-[20px] font-medium inline-block min-w-[21px]">
        {locale === 'vn' ? 'Vn' : 'En'}
      </div>
      <FaAngleDown size={16} />
    </div>
  )
}

export default LocaleDropdown
