import LoginButton from '@/components/auth/LoginButton'
import RegisterButton from '@/components/auth/RegisterButton'
import LocaleDropdown from '@/components/layouts/header/LocaleDropdown'

function AuthSession() {
  return (
    <div className="flex flex-row items-center justify-end gap-3">
      <LocaleDropdown />
      <RegisterButton />
      <LoginButton />
    </div>
  )
}

export default AuthSession
