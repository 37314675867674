import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import {Button} from "@/components/ui/button";
import {FaBars} from "react-icons/fa6";
import {Link} from "@/navigation";
import Image from "next/image";
import logoLight from "@/assets/images/logo-light.png";
import {BtnClose} from "@/components/common/BtnClose";
import {useSession} from "next-auth/react";
import avatar from "@/assets/images/navbar/avatar.png";
import LoginButton from "@/components/auth/LoginButton";
import RegisterButton from "@/components/auth/RegisterButton";
import { FaArrowRightFromBracket } from "react-icons/fa6";
import VerticalMenu from "@/components/layouts/VerticalMenu";
import {getHorizontalMenuItems} from "@/helpers";

export default function ButtonMobileMenu() {
  const {status} = useSession()
  const { data: session } = useSession()

  return (
    <Drawer direction="right">
      <DrawerTrigger>
        <FaBars size={24}/>
      </DrawerTrigger>
      <DrawerContent className="h-screen rounded-none">
        <DrawerHeader className="flex justify-between py-6">
          <DrawerTitle>
            <Link href="/">
              <Image alt="Logo" className="h-8" src={logoLight} width={126}/>
            </Link>
          </DrawerTitle>
          <DrawerClose>
            <BtnClose/>
          </DrawerClose>
        </DrawerHeader>
        <VerticalMenu menuItems={getHorizontalMenuItems()} />
        <DrawerFooter className="border-t border-base-gray-200">
          {
            status !== 'authenticated' ? (
              <div className="w-full flex flex-col gap-y-3">
                <LoginButton />
                <RegisterButton />
              </div>
            ) : (
              <div className="w-full flex flex-row justify-between items-center gap-y-3">
                <div className="flex flex-row gap-x-2">
                  <div
                    className="h-10 w-10 flex flex-col items-center justify-start pt-0 pr-0 box-border relative ml-6 cursor-pointer">
                    <Image alt="avatar" height={48} src={avatar} width={48}/>
                  </div>
                  <div>
                    <h6 className="text-lg font-semibold">{session?.user?.name}</h6>
                    <p className="text-sm text-slate-500">{session?.user?.email}</p>
                  </div>
                </div>
                <FaArrowRightFromBracket className="text-base-gray-600" size="20"/>
              </div>
            )
          }
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}