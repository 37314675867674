'use client'
import { useTranslations } from 'next-intl'
import React, { useCallback, useEffect, useState } from 'react'
import { useRegisterContext } from '@/components/auth/context/useRegister'
import { Button } from '@/components/ui/button'
import { formatPhoneNumber } from '@/utils'
import { getAuth, signInWithPhoneNumber } from 'firebase/auth'
import { app } from '@/firebase/config'

const TIME_COUNT_DOWN = 30
let idInterval: any = null

// Our parent component
function VerifyPhoneForm() {
  const auth = getAuth(app)

  const { ConfirmationResult, setStep, registerData, setConfirmationResult } =
    useRegisterContext()
  const t = useTranslations('auth.singup')

  // state to store all input boxes
  const [inputValues, setInputValues] = useState({
    input1: '',
    input2: '',
    input3: '',
    input4: '',
    input5: '',
    input6: '',
    // Add more input values here
  })
  const [timeCountDown, setTimeCountDown] = useState<number>(TIME_COUNT_DOWN)

  // this function updates the value of the state inputValues
  function handleInputChange(inputId: any, value: any) {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [inputId]: value,
    }))
  }

  const handleResendOtp = useCallback(async () => {
    try {
      if (timeCountDown === 0) {
        // Format phone number nếu số 0 ở đầu chuyển thành 84 nếu là 84 thì giữ nguyên
        const newPhone = formatPhoneNumber(registerData.phone)

        const formattedPhoneNumber = `+${newPhone.replace(/\D/g, '')}`

        const confirmation = await signInWithPhoneNumber(
          auth,
          formattedPhoneNumber,
          window?.recaptchaVerifier
        )

        if (confirmation.verificationId) {
          setConfirmationResult(confirmation)
          setTimeCountDown(TIME_COUNT_DOWN)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }, [registerData])

  // this function processes form submission
  function handleSubmit(e: { preventDefault(): void }) {
    e.preventDefault()
    const otp =
      inputValues.input1 +
      inputValues.input2 +
      inputValues.input3 +
      inputValues.input4 +
      inputValues.input5 +
      inputValues.input6

    ConfirmationResult.confirm(otp)
      .then(() => {
        setStep(3)
      })
      .catch((error: any) => {
        console.error(error)
      })
  }

  useEffect(() => {
    idInterval = setInterval(() => {
      if (timeCountDown > 0) {
        setTimeCountDown(timeCountDown - 1)
      }
    }, 1000)
    return () => {
      if (idInterval) {
        clearInterval(idInterval)
      }
    }
  }, [timeCountDown])

  // return child component
  return (
    <>
      <div className="flex flex-col gap-6">
        <div
          className="flex-1 flex flex-row items-center justify-center gap-2 max-w-full"
          data-autosubmit="true"
          id="OTPInputGroup"
        >
          <OTPInput
            id="input1"
            nextId="input2"
            onValueChange={handleInputChange}
            previousId={null}
            value={inputValues.input1}
          />
          <OTPInput
            id="input2"
            nextId="input3"
            onValueChange={handleInputChange}
            previousId="input1"
            value={inputValues.input2}
          />
          <OTPInput
            id="input3"
            nextId="input4"
            onValueChange={handleInputChange}
            previousId="input2"
            value={inputValues.input3}
          />
          <OTPInput
            id="input4"
            nextId="input5"
            onValueChange={handleInputChange}
            previousId="input3"
            value={inputValues.input4}
          />
          <OTPInput
            id="input5"
            nextId="input6"
            onValueChange={handleInputChange}
            previousId="input4"
            value={inputValues.input5}
          />
          <OTPInput
            id="input6"
            onValueChange={handleInputChange}
            previousId="input5"
            value={inputValues.input6}
          />
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
          <Button
            className="cursor-pointer py-2 px-5 bg-brand-solid self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg overflow-hidden flex flex-row items-center justify-center gap-[6px] border-[1px] border-solid border-brand-500-base"
            onClick={handleSubmit}
            type="submit"
          >
            <div className="flex flex-row items-center justify-center py-0 px-0.5">
              <div className="relative text-white leading-[24px] font-semibold font-text-sm-semibold text-base-white text-left inline-block min-w-[64px]">
                {t('tiep_tuc')}
              </div>
            </div>
          </Button>
        </div>
        <div>
          <span className="text-sm text-base-gray-600 font-normal">
            {t('otp_not_received')}
          </span>
          <span
            className="text-sm font-semibold text-base-blue-600"
            onClick={handleResendOtp}
            style={
              timeCountDown === 0
                ? { cursor: 'pointer' }
                : { cursor: 'not-allowed' }
            }
          >
            {' '}
            {t('gui_lai')}
          </span>{' '}
          {timeCountDown !== 0 && (
            <span className="text-sm text-base-gray-600 font-normal">
              sau {timeCountDown} giây
            </span>
          )}
        </div>
      </div>
    </>
  )
}

type OTPInputProps = {
  id: string
  nextId?: string
  onValueChange(id: string, value: string): void
  previousId: string | null
  value: string
}

// Our child component
function OTPInput({
  id,
  nextId,
  onValueChange,
  previousId,
  value,
}: OTPInputProps) {
  // This callback function only runs when a key is released
  function handleKeyUp(e: { keyCode: number }) {
    // check if key is backspace or arrowleft
    if (e.keyCode === 8 || e.keyCode === 37) {
      // find the previous element
      if (typeof previousId === 'string') {
        const prev = document.getElementById(
          previousId
        ) as HTMLInputElement | null
        if (prev != null) {
          // select the previous element
          prev.select()
        }
      }
    } else if (
      (e.keyCode >= 48 && e.keyCode <= 57) || // check if key is numeric keys 0 to 9
      (e.keyCode >= 65 && e.keyCode <= 90) || // check if key is alphabetical keys A to Z
      (e.keyCode >= 96 && e.keyCode <= 105) || // check if key is numeric keypad keys 0 to 9
      e.keyCode === 39 // check if key is right arrow key
    ) {
      // find the next element
      if (typeof nextId === 'string') {
        const next = document.getElementById(nextId) as HTMLInputElement | null

        if (next) {
          // select the next element
          next.select()
        } else {
          // check if inputGroup has autoSubmit enabled
        }
      }
    }
  }

  return (
    <input
      className="[outline:none] bg-[transparent] h-12 rounded-lg box-border overflow-hidden text-base text-center text-global-gray-800 w-full border border-solid border-gray-300"
      id={id}
      maxLength={1}
      name={id}
      onChange={(e) => onValueChange(id, e.target.value)}
      onKeyUp={handleKeyUp}
      type="text"
      value={value}
    />
  )
}

export default VerifyPhoneForm
