import http from "@/lib/http";

const notificationApiRequest = {
  getNotifications: (queryString: string, token: string) => http.get<any>(`/notification/list?${queryString}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }),
  readNotification: (id: number, token: string) => http.put<any>(`/notification/read/${id}`, {}, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }),
}

export default notificationApiRequest