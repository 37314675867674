'use client'
import { signIn } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import { useForm } from 'react-hook-form'
import InputField from '../common/InputField'
import { useRegisterContext } from './context/useRegister'
import {
  getSchemaConfirmPassword,
  RegisterPasswordType,
} from '@/schema/auth.schema'
import { encryptTextAES } from '@/utils/CryptoJSLib'
import { ListRole, ListStatus } from '@/constants'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'

type RegisterDataType = {
  full_name: string
  phone: string
  password: string
  role: string
  status: string
  redirect: boolean
  callbackUrl?: string
}

function PasswordForm() {
  const t = useTranslations('auth.singup')
  const { registerData } = useRegisterContext()

  const form = useForm<RegisterPasswordType>({
    resolver: zodResolver(getSchemaConfirmPassword(t)),
    defaultValues: {
      password: '',
      confirm_password: '',
    },
  })

  async function onSubmit(values: RegisterPasswordType) {
    if (values.password !== values.confirm_password) {
      return
    }

    const data: RegisterDataType = {
      full_name: registerData.full_name,
      phone: registerData.phone,
      password: encryptTextAES(values.password),
      role: ListRole.OWNER,
      status: ListStatus.ACTIVE,
      redirect: true,
      callbackUrl: process.env.NEXT_PUBLIC_CLIENT_URL,
    }

    try {
      await signIn('register', data)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Form {...form}>
      <form
        className="m-0 self-stretch rounded-xl flex flex-col items-center justify-start gap-[24px] max-w-full"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="self-stretch flex flex-col items-start justify-start gap-[20px] max-w-full">
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <InputField
                    contentPlaceholder={t('nhap_mat_khau')}
                    iconPassword
                    label={t('mat_khau')}
                    type="password"
                    field={field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="confirm_password"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <InputField
                    contentPlaceholder={t('nhap_lai_mat_khau')}
                    iconPassword
                    label={t('nhap_lai_mat_khau')}
                    type="password"
                    field={field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
          <button
            className="cursor-pointer py-2 px-5 bg-brand-solid self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg overflow-hidden flex flex-row items-center justify-center gap-[6px] border-[1px] border-solid border-brand-500-base"
            type="submit"
          >
            <div className="flex flex-row items-center justify-center py-0 px-0.5">
              <div className="relative text-white leading-[24px] font-semibold font-text-sm-semibold text-base-white text-left inline-block min-w-[64px]">
                {t('tao_tai_khoan')}
              </div>
            </div>
          </button>
        </div>
      </form>
    </Form>
  )
}

export default PasswordForm
