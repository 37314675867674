// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCVSdh-Z8aukjRKlbwvSTIiZatmjTTVmD0',
  authDomain: 'rentalcar-cd868.firebaseapp.com',
  projectId: 'rentalcar-cd868',
  storageBucket: 'rentalcar-cd868.appspot.com',
  messagingSenderId: '389926393088',
  appId: '1:389926393088:web:1fc635da660a15d54a0fe5',
  measurementId: 'G-147MJ68ZNY',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export { app }
