import http from "@/lib/http";
import {LoginResType, UserMeResType} from "@/schema/auth.schema";

const authApiRequest = {
  register: (body: any) => http.post<any>('/register', body),
  login: (body: any) => http.post<LoginResType>('/login', body),
  me: (token: string) => http.get<UserMeResType>('/user/me', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }),
  sendEmailOTP: (body: any, token: string) => http.post<any>('/mail/send-otp', body, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }),
  verifyEmail: (body: any, token: string) => http.post<any>('/mail/verify-otp', body, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }),
  userUpdate: (body: any, token: string) => http.put<any>('/user/update', body, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }),
  verifyPhone: (body: any, token: string) => http.post<any>('/phone/verify-otp', body, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }),
  changePassword: (body: any, token: string) => http.post<any>('/user/change-password', body, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }),
  deleteAccount: (id: string | undefined, token: string) => http.delete<any>(`/user/delete/${id}`, {}, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export default authApiRequest