'use client'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { signOut, useSession } from 'next-auth/react'
import { useTheme } from 'next-themes'
import avatar8 from '@/assets//images/navbar/avatar.png'
import avatar from '@/assets/images/navbar/avatar.png'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { Switch } from '@/components/ui/switch'
import {
  TicketSvg,
  SaleSvg,
  HeartSvg,
  SettingSvg,
  MoonSvg,
  LogoutSvg,
} from '@/icons-svg/HomeSvgIcons'

function ProfileDropdown() {
  const { setTheme } = useTheme()
  const { data: session } = useSession()
  const router = useRouter()
  const BASE_URL = process.env.NEXT_PUBLIC_API_URL

  function handleLogout() {
    fetch(BASE_URL + '/user/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${session?.accessToken}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
      })
      .then((res) => {
        if (res?.code === 200) {
          signOut({ redirect: false })
            .then(() => router.push('/'))
            .catch(() => {
              // catch error
            })
        }
      })
      .catch(() => {
        // catch error
      })
  }

  return (
    <div className="nav-item">
      <Popover>
        <PopoverTrigger>
          <PopoverToggle2 />
        </PopoverTrigger>
        <PopoverContent>
          <div className="transition-all translate-y-3 bg-white rounded-2.5xl shadow-lg p-4 w-72">
            <div className="flex items-center gap-2 py-4 px-4 border-b">
              <Image
                alt="avatar"
                className="rounded-full"
                height={40}
                src={avatar8}
                width={40}
              />
              <div>
                <h6 className="text-lg font-semibold">{session?.user?.name}</h6>
                <p className="text-sm text-slate-500">{session?.user?.email}</p>
              </div>
            </div>
            <div className="my-4 pb-4 flex flex-col border-b">
              <div className="rounded transition-all px-4 py-1">
                <Link className="flex !p-2" href="/order">
                  <TicketSvg />
                  <span className="pl-4 font-semibold">Đơn hàng</span>
                </Link>
              </div>
              <div className="rounded transition-all px-4 py-1">
                <Link className="flex !p-2" href="/voucher">
                  <SaleSvg />
                  <span className="pl-4 font-semibold">Mã ưu đãi</span>
                </Link>
              </div>
              <div className="rounded transition-all px-4 py-1">
                <Link className="flex !p-2" href="/wishlist">
                  <HeartSvg />
                  <span className="pl-4 font-semibold">Yêu thích</span>
                </Link>
              </div>
              <div className="rounded transition-all px-4 py-1">
                <Link className="flex !p-2" href="/setting/profile">
                  <SettingSvg />
                  <span className="pl-4 font-semibold">Cài đặt</span>
                </Link>
              </div>
            </div>
            <div className="my-4 flex flex-col">
              <div className="rounded transition-all px-4 py-1 flex justify-between items-center">
                <Link className="flex !p-2" href="">
                  <MoonSvg />
                  <span className="pl-4 font-semibold"> Chế độ tối </span>
                </Link>
                <Switch
                  className="group relative flex h-7 w-14 cursor-pointer rounded-full bg-gray-100 p-1 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-base-blue-400"
                  onCheckedChange={(e) => {
                    setTheme(e ? 'dark' : 'light')
                  }}
                >
                  <span
                    aria-hidden="true"
                    className="pointer-events-none inline-block size-5 translate-x-0 rounded-full bg-white ring-0 shadow-lg transition duration-200 ease-in-out group-data-[checked]:translate-x-7"
                  />
                </Switch>
              </div>
              <div className="rounded transition-all px-4 py-1">
                <Link className="flex !p-2" href="" onClick={handleLogout}>
                  <LogoutSvg />
                  <span className="pl-4 font-semibold">Đăng xuất</span>
                </Link>
              </div>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}

function PopoverToggle2() {
  return (
    <div className="h-10 w-10 flex flex-col items-center justify-start pt-0 pr-0 box-border relative ml-6 cursor-pointer">
      <Image alt="avatar" height={40} src={avatar} width={40} />
    </div>
  )
}

export default ProfileDropdown
