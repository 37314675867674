import {Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger} from "@/components/ui/dialog";
import {useState} from "react";
import {Button} from "@/components/ui/button";
import {EmailSvg, NotiDotSvg} from "@/icons-svg/HomeSvgIcons";
import {clsx} from "clsx";
import {useFormatter} from 'next-intl';
import moment from "moment/moment";

interface NotiDetailProps {
  index: number
  notification: any
}

export default function NotificationDetail({index, notification}: NotiDetailProps) {
  const format = useFormatter();
  const dateCreate = moment(notification.created_at);
  const now = moment();

  return (
    <Dialog>
      <DialogTrigger asChild>
        <li
          key={index}
          className={clsx('flex items-center gap-4 py-4 cursor-pointer', {
            'border-b': index !== 4,
          })}
        >
          <div className="min-w-12 h-12 bg-base-brand-50 rounded-full flex justify-center items-center">
            <EmailSvg/>
          </div>
          <div className="">
            <h4 className="font-semibold text-base text-gray-900">
              {notification?.title}
            </h4>
            <p className="text-sm text-gray-700">
              {notification?.content}
            </p>
            <p className="text-gray-500 text-xs">{format.relativeTime(dateCreate.toDate(), now.toDate())}</p>
          </div>
          <div>
            <NotiDotSvg/>
          </div>
        </li>
      </DialogTrigger>
      <DialogContent className="sm:max-w-2xl">
        <DialogTitle hidden>Notification Detail</DialogTitle>
        <DialogDescription hidden>Notification content</DialogDescription>
        <div className="w-full h-screen md:h-auto block">
          <div className="bg-base-gray-200 h-80 w-full rounded-2xl mt-10"></div>
          <div className="mt-5">
            <h6 className="font-semibold text-2xl text-primary mb-4">{notification?.title}</h6>
            <div className="flex flex-col gap-y-2">
              <p>{notification?.content}</p>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}