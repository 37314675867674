'use client'
import Image from 'next/image'
import thumnail1 from '@/assets/images/hero/auth-thumnail.svg'
import RegisterContainer from '@/components/auth/RegisterContainer'
import RegisterProvider from '@/components/auth/context/useRegister'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { useTranslations } from 'next-intl'
import { useEffect, useState } from 'react'
import { useRouter, useSearchParams } from 'next/navigation'

export default function RegisterButton() {
  const t = useTranslations('auth.singup')
  const searchParams = useSearchParams()
  const router = useRouter()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (searchParams.get('isOpenModalSignUp') === '1') {
      setOpen(true)
      router.push('/')
    }
  }, [searchParams])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        asChild
        onClick={() => {
          setOpen(true)
        }}
      >
        <Button
          className="font-semibold text-base hover:bg-base-gray-900 hover:text-white"
          variant="outline"
        >
          {t('dang_ky')}
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-screen-xl">
        <DialogTitle hidden>{t('dang_ky')}</DialogTitle>
        <DialogDescription hidden>{t('dang_ky_description')}</DialogDescription>
        <div className="w-full h-screen md:h-auto block md:grid md:grid-cols-6 md:gap-4 relative">
          <div className="hidden md:block md:col-span-2">
            <Image
              alt=""
              className="w-5/6 h-full"
              height={912}
              loading="lazy"
              src={thumnail1}
              width={504}
            />
          </div>
          <div className="md:col-span-4 h-full w-screen md:w-auto relative">
            <RegisterProvider>
              <RegisterContainer />
            </RegisterProvider>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
