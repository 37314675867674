'use client'
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth'
import { useTranslations } from 'next-intl'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import InputField from '../common/InputField'
import { useRegisterContext } from '@/components/auth/context/useRegister'
import { app } from '@/firebase/config'
import { getSchemaRegister, RegisterSendOtpType } from '@/schema/auth.schema'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { zodResolver } from '@hookform/resolvers/zod'
import { formatPhoneNumber } from '@/utils'
import { Button } from '@/components/ui/button'

declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier
  }
}

function RegisterContainer() {
  const auth = getAuth(app)
  const t = useTranslations('auth.singup')
  const { setConfirmationResult, setRegisterData, setStep } =
    useRegisterContext()

  const form = useForm<RegisterSendOtpType>({
    resolver: zodResolver(getSchemaRegister(t)),
    defaultValues: {
      full_name: '',
      phone: '',
    },
  })

  async function onSubmit(values: RegisterSendOtpType) {
    if (!values.phone) return
    setRegisterData({ ...values })
    await handleSendOtp(values)
  }

  async function handleSendOtp(e: RegisterSendOtpType) {
    try {
      // Format phone number nếu số 0 ở đầu chuyển thành 84 nếu là 84 thì giữ nguyên
      const newPhone = formatPhoneNumber(e.phone)

      const formattedPhoneNumber = `+${newPhone.replace(/\D/g, '')}`

      const confirmation = await signInWithPhoneNumber(
        auth,
        formattedPhoneNumber,
        window?.recaptchaVerifier
      )

      if (confirmation.verificationId) {
        setConfirmationResult(confirmation)
        setStep(2)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    // Initialize RecaptchaVerifier when '(auth)' changes
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      'recaptcha-container',
      {
        size: 'invisible',
        callback: () => {},
        'expired-callback': () => {},
      }
    )
  }, [auth])

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8 w-full">
        <div className="m-0 self-stretch rounded-xl flex flex-col gap-y-5 items-center justify-start w-full">
          <div className="self-stretch flex flex-col items-start justify-start gap-y-5 w-full">
            <FormField
              control={form.control}
              name="full_name"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputField
                      contentPlaceholder={t('nhap_ho_ten')}
                      label={t('ho_ten')}
                      field={field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputField
                      contentPlaceholder={t('nhap_sdt')}
                      label={t('sdt')}
                      field={field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="self-stretch flex flex-col items-start justify-start mt-1">
            <Button
              className="cursor-pointer py-2 px-5 bg-brand-solid self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg overflow-hidden flex flex-row items-center justify-center gap-[6px] border-[1px] border-solid border-brand-500-base"
              type="submit"
              disabled={
                form.getValues('full_name') === '' ||
                form.getValues('phone') === ''
              }
            >
              <div className="flex flex-row items-center justify-center py-0 px-0.5">
                <div className="relative text-white leading-[24px] font-semibold font-text-sm-semibold text-base-white text-left inline-block min-w-[64px]">
                  {t('tiep_tuc')}
                </div>
              </div>
            </Button>
          </div>
        </div>
      </form>
    </Form>
  )
}

export default RegisterContainer
