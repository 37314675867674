'use client'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FaChevronDown } from 'react-icons/fa6'
import { MenuItemTypes } from '@/constants/MenuItems'
import { cn } from '@/helpers'
import { findAllParent, findMenuItem, getMenuItemFromURL } from '@/helpers/menu'
import {useSession} from "next-auth/react";

type SubMenusProps = {
  item: MenuItemTypes
  linkClassName?: string
  activeMenuItems?: Array<string>
  toggleMenu?(item: any, status: boolean): void
  level: number
}

function MenuItemWithChildren({
  activeMenuItems,
  item,
  level,
  linkClassName,
  toggleMenu,
}: SubMenusProps) {
  const [open, setOpen] = useState<boolean>(activeMenuItems!.includes(item.key))

  useEffect(() => {
    setOpen(activeMenuItems!.includes(item.key))
  }, [activeMenuItems, item])

  function toggleMenuItem() {
    const status = !open
    setOpen(status)
    if (toggleMenu) toggleMenu(item, status)
  }

  return (
    <li
      className={cn(
        'hs-dropdown [--strategy:static] [--adaptive:none]  [--placement:right-top] relative '
      )}
    >
      <div
        aria-expanded={open}
        className={cn(
          'hs-dropdown-toggle  flex justify-between items-center cursor-pointer py-1',
          activeMenuItems!.includes(item.key) && 'active',
          level === 1 ? 'px-1' : 'px-4'
        )}
        data-menu-key={item.key}
        id={`hs-dropdown-${item.key}`}
        onClick={toggleMenuItem}
        onKeyDown={toggleMenuItem}
        role="button"
        tabIndex={0}
      >
        <span
          className={cn(
            'font-medium',
            activeMenuItems?.includes(item.key)
              ? 'text-primary'
              : 'text-gray-800 '
          )}
        >
          {item.label}
        </span>
        {!item.badge && (
          <span
            className={cn(
              'transition-all ms-2 hs-dropdown-open:rotate-180',
              activeMenuItems?.includes(item.key)
                ? 'text-primary'
                : 'text-gray-800 '
            )}
          >
            <FaChevronDown size={12} />
          </span>
        )}
      </div>
      <div
        className={cn(
          'hs-dropdown-menu mt-0 transition-all z-10 duration hs-dropdown-open:opacity-100 origin-center absolute hidden',
          open && 'active',
          linkClassName,
          level !== 1 && 'left-full'
        )}
      >
        <ul
          aria-labelledby={`hs-dropdown-${item.key}`}
          className=" bg-white rounded-lg shadow-lg border p-2 w-48 pt-2"
        >
          {(item.children ?? []).map((child) => (
            <Fragment key={child.key}>
              {child.children ? (
                <MenuItemWithChildren
                  activeMenuItems={activeMenuItems}
                  item={child}
                  level={level + 1}
                  linkClassName={cn(
                    'nav-link z-20',
                    activeMenuItems!.includes(child.key) && 'active'
                  )}
                  toggleMenu={toggleMenu}
                />
              ) : (
                <MenuItem
                  className="nav-item"
                  item={child}
                  // level={level + 1}
                  linkClassName={cn(
                    'nav-link',
                    activeMenuItems!.includes(child.key) && 'active'
                  )}
                />
              )}
            </Fragment>
          ))}
        </ul>
      </div>
      {item.isDivider && <hr className="-mx-2 my-2" />}
    </li>
  )
}

type MenuItemProps = {
  item: MenuItemTypes
  className?: string
  linkClassName: string
}

function MenuItem({ className, item, linkClassName }: MenuItemProps) {
  return (
    <li className={className}>
      <MenuItemLink className={linkClassName} item={item} />
    </li>
  )
}

type MenuItemLinkProps = {
  item: MenuItemTypes
  className: string
}

function MenuItemLink({ className, item }: MenuItemLinkProps) {
  return (
    <Link
      className={`nav-link ${className}`}
      data-menu-key={item.key}
      href={item.url ?? ''}
      target={item.target ?? '_self'}
    >
      {item.icon ? (
        <div className="flex items-center -ms-1.5">
          <span className="bg-blue-600/10 flex justify-center items-center w-8 h-8 shadow rounded me-3">
            {item.icon}
          </span>
          <div className="flex-grow-1">{item.label}</div>
        </div>
      ) : (
        <div className="flex flex-wrap justify-between font-medium text-base-gray-600">
          {item.label}
        </div>
      )}
    </Link>
  )
}

/**
 * Renders the application menu
 */
type AppMenuProps = {
  menuItems: Array<MenuItemTypes>
}

function AppMenu({ menuItems }: AppMenuProps) {
  const {status} = useSession()
  const [activeMenuItems, setActiveMenuItems] = useState<Array<string>>([])

  const location = usePathname()
  const menuRef = useRef(null)

  function toggleMenu(menuItem: MenuItemTypes, show: boolean) {
    if (show) {
      setActiveMenuItems([menuItem.key, ...findAllParent(menuItems, menuItem)])
    }
  }

  /**
   * activate the menuitems
   */
  const activeMenu = useCallback(() => {
    const trimmedURL = location?.replaceAll('', '')
    const matchingMenuItem = getMenuItemFromURL(menuItems, trimmedURL)

    if (matchingMenuItem) {
      const activeMt = findMenuItem(menuItems, matchingMenuItem.key)
      if (activeMt) {
        setActiveMenuItems([
          activeMt.key,
          ...findAllParent(menuItems, activeMt),
        ])
      }
    }
  }, [location, menuItems])

  useEffect(() => {
    activeMenu()
  }, [activeMenu, menuItems])

  return (
    <ul ref={menuRef} className="flex gap-x-8 items-center justify-center">
      {(menuItems ?? []).map((item) => (
        !item.auth || status === 'authenticated' ? (
          <Fragment key={item.key}>
            {item.children ? (
              <MenuItemWithChildren
                activeMenuItems={activeMenuItems}
                item={item}
                level={1}
                linkClassName={`nav-link ${activeMenuItems.includes(item.key) ? 'active' : ''}`}
                toggleMenu={toggleMenu}
              />
            ) : (
              <MenuItem
                className="nav-item"
                item={item}
                // level={1}
                linkClassName={activeMenuItems.includes(item.key) ? 'active' : ''}
              />
            )}
          </Fragment>
        ) : ""
      ))}
    </ul>
  )
}

export default AppMenu
