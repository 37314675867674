"use client";
import React, {useEffect} from "react";
import {sessionToken} from "@/lib/http";
import {useSession} from "next-auth/react";
import authApiRequest from "@/api-data/auth";

export default function AppProvider({children}: { children: React.ReactNode }) {
  const {data: session} = useSession()

  const GetUserMe = async () => {
    const user = localStorage.getItem("user")

    if (!user) {
      const {payload} = await authApiRequest.me(session?.accessToken || "")

      localStorage.setItem("user", JSON.stringify(payload.data))
    }
  }

  useEffect(() => {
    sessionToken.value = session?.accessToken ?? ""
    if (session?.accessToken) {
      GetUserMe()
    }
  }, [session?.accessToken]);

  return (
    <>
      {children}
    </>
  )
};