'use client'
import Image from 'next/image'
import Link from 'next/link'
import { signIn } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import RegisterForm from './RegisterForm'
import google from '@/assets/images/google.svg'
import logo from '@/assets/images/logo.svg'
import PasswordForm from '@/components/auth/PasswordForm'
import VerifyPhoneForm from '@/components/auth/VerifyPhoneForm'
import { useRegisterContext } from '@/components/auth/context/useRegister'
import { toast } from 'sonner'

function RegisterContainer() {
  const t = useTranslations('auth.singup')

  const { step } = useRegisterContext()

  function RegisterGoogle() {
    signIn('google', { callbackUrl: process.env.NEXT_PUBLIC_CLIENT_URL })
      // eslint-disable-next-line no-console
      .then((r) => console.log(r))
      // eslint-disable-next-line no-console
      .catch((e) => console.log(toast.error('Mã OTP không hợp lệ.')))
  }

  return (
    <div className="h-full self-stretch flex flex-col items-center justify-center py-0 md:px-5 box-border max-w-full text-center text-11xl text-gray-900 font-text-sm-semibold">
      <div className="flex flex-col items-center justify-start w-full max-w-sm">
        <div className="self-stretch flex flex-col items-center justify-start gap-[24px] mb-8">
          <Image
            alt="logo tipgroup"
            className="w-12 h-12 relative"
            height={48}
            loading="lazy"
            src={logo}
            width={48}
          />
          <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
            <h1 className="m-0 self-stretch relative leading-[38px] font-semibold text-3xl">
              {step === 1
                ? t('dang_ky')
                : step === 2
                  ? t('nhap_ma_xac_minh')
                  : t('nhap_mat_khau')}
            </h1>
          </div>
        </div>
        {step === 1 && <RegisterForm />}
        {step === 2 && <VerifyPhoneForm />}
        {step === 3 && <PasswordForm />}
        {step === 1 && (
          <div className="mt-4 w-full">
            <button
              className="w-full cursor-pointer [border:none] p-0 bg-[transparent] self-stretch flex flex-col items-center justify-center gap-[12px]"
              onClick={RegisterGoogle}
              type="button"
            >
              <div className="self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg bg-base-white overflow-hidden flex flex-row items-center justify-center py-2 px-5 gap-[11px] whitespace-nowrap border-[1px] border-solid border-gray-300">
                <Image
                  alt=""
                  className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
                  height={24}
                  src={google}
                  width={24}
                />
                <div className="relative text-base leading-[24px] font-semibold font-text-sm-semibold text-gray-700 text-left">
                  {t('dang_ky_google')}
                </div>
              </div>
            </button>
            <div className="mt-8 flex flex-row items-baseline justify-center py-0 gap-[4px] text-left text-sm text-gray-600">
              <div className="relative leading-[20px]">{t('co_tai_khoan')}</div>
              <div className="overflow-hidden flex flex-row items-center justify-center gap-[6px] text-link">
                <div className="relative leading-[20px] font-semibold inline-block">
                  <a href="/?isOpenModaSignIn=1">{t('dang_nhap')}</a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mt-8 relative text-sm leading-[20px] inline-block max-w-md text-gray-600">
        <span>{t('policy_1')}</span>
        <a href="/terms" target="_blank" className="font-medium text-link">
          {t('dieu_khoan')}
        </a>
        <span>{t('and')}</span>
        <a href="/privacy" target="_blank" className="font-medium text-link">
          {t('chinh_sach')}
        </a>
      </div>
    </div>
  )
}

export default RegisterContainer
