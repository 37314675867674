'use client'
import { createContext, useContext, useState, ReactNode } from 'react'

const RegisterContext = createContext<any>(null)

interface RegisterContextType {
  full_name: string
  phone: string
  password: string
}

export default function RegisterProvider({
  children,
}: {
  children: ReactNode
}) {
  const [step, setStep] = useState(1)
  const [ConfirmationResult, setConfirmationResult] = useState<any>(null)
  const [registerData, setRegisterData] = useState<RegisterContextType>({
    full_name: '',
    phone: '',
    password: '',
  })

  return (
    <RegisterContext.Provider
      value={{
        step,
        setStep,
        ConfirmationResult,
        setConfirmationResult,
        registerData,
        setRegisterData,
      }}
    >
      {children}
    </RegisterContext.Provider>
  )
}

export function useRegisterContext() {
  return useContext(RegisterContext)
}
